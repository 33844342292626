/* eslint-disable valid-jsdoc */
'use strict';

const selectors = {
    countdownTimer: '.js-countdown-timer',
    days: '#days',
    hours: '#hours',
    minutes: '#minutes',
    seconds: '#seconds',
    countdownBG: '.countdown-bg'
};

/**
* Gets the index number of a month based on month name input
*/
function getMonthNumber(month) {
    const monthsArray = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
    const monthIndex = monthsArray.indexOf(month);

    return monthIndex;
}

/**
* Gets the remaining time based on a deadline. The deadline must be a valid date.
*/
function getRemainingTime(deadline) {
    const now = new Date(); // This is returning the incorrect current date/time. Its -0000 but should be GMT+0200
    const currentTime = now.getTime();
    const endTime = deadline.getTime();
    const totalTime = endTime - currentTime;
    const remainingSeconds = Math.floor((totalTime / 1000) % 60);
    const remainingMinutes = Math.floor((totalTime /(1000 * 60)) % 60);
    const remainingHours = Math.floor((totalTime /(1000 * 60 * 60)) % 24);
    const remainingDays = Math.floor(totalTime /(1000 * 60 * 60 * 24));

    return { totalTime, remainingSeconds, remainingMinutes, remainingHours, remainingDays };
}

const countdownTimers = document.querySelectorAll(selectors.countdownTimer);

/**
* Initializes the timer
*/
function initTimer() {
    if (countdownTimers.length <= 0) {
        return;
    }

	// Grab data attributes and account for more than one countdown on a page
    Array.from(countdownTimers).forEach(timer => {
		// Grab date parameters
        const minutes = timer.dataset.minutes;
        const hour = timer.dataset.hour;
        const day = timer.dataset.day;
        const month = timer.dataset.month;
        const year = timer.dataset.year;

		// Grab time spans
        const daysSpan = timer.querySelector(selectors.days);
        const hoursSpan = timer.querySelector(selectors.hours);
        const minutesSpan = timer.querySelector(selectors.minutes);
        const secondsSpan = timer.querySelector(selectors.seconds);

		// Create end date
        const monthNumber = getMonthNumber(month);
        const deadline = new Date(year, monthNumber, day, hour, minutes);

        // Create timer
        const countdownTimer = setInterval(() => {
            timer.closest(selectors.countdownBG).classList.add('timer-on');
            const time = getRemainingTime(deadline);
            daysSpan.innerHTML = `${time.remainingDays < 10 ? `0${time.remainingDays}` : time.remainingDays}`;
            hoursSpan.innerHTML = `${time.remainingHours < 10 ? `0${time.remainingHours}` : time.remainingHours}`;
            minutesSpan.innerHTML = `${time.remainingMinutes < 10 ? `0${time.remainingMinutes}` : time.remainingMinutes}`;
            secondsSpan.innerHTML = `${time.remainingSeconds < 10 ? `0${time.remainingSeconds}` : time.remainingSeconds}`;

            if (time.totalTime <= 0) {
                clearInterval(countdownTimer);
                timer.closest(selectors.countdownBG).classList.remove('timer-on');
            }
        }, 1000);
    });
}

module.exports = {
    init: initTimer
};
