'use strict';

const CONST = require('plugin_frontend_core/constants');
const cwUtil = require('plugin_frontend_core/utils/utils_cw');
const dropdownMenu = require('./dropdown-menu');

var selectors = {
    // generic
    mainMenu: '.js-main-menu',
    navbar: '.js-navbar-nav',

    // header
    primaryHeader: '.js-header-primary',

    // triggers
    mobileMenuTrigger: '.js-navbar-toggler',
    mobileMenuTriggerIcon: '.js-navbar-toggler-icon',

    // navbar levels

    navbarLevels: [ // @TODO LROS: How about navbarLevels: '.js-nav-level-' and add a navbarDepth variable (3) which can be configured in JSON config?
        '.js-nav-level-1',
        '.js-nav-level-2',
        '.js-nav-level-3'
    ],
    navItemToggler: '.nav-item-toggler',
    navbarLevelOneLinks: '.js-nav-level-1-link',

    // non-navbar items
    modalBackground: '.modal-background' // @TODO LROS: create page.isml template in app_clockwork_orange and refactor to js-class
};

var $menuDropdownTriggers;
var $doc;
var $body;
var $htmlBody;
var $primaryHeader;

var $navBar;
var $levelOneLinks;
var $mainMenu;
var $modalBackground;


/**
 * Closes all sublevels of mobile menu
 */
function closeAllLevels() {
    $navBar.find(selectors.navbarMenu).detach();
    $navBar.find('.' + CONST.classes.show).removeClass(CONST.classes.show);
    $navBar.find(selectors.navItemToggler).addClass(CONST.classes.collapsed);
}

/**
 * Opens the mobile menu level relative to the current trigger
 * @param {HtmlEvent} e -
 */
function openLevel(e) {
    // JVP @TODO: Prevent default on elements that collapse but also make it possible to navigate to the link
    // e.preventDefault();
    var $currentDropdownTrigger = $(this);
    var $currentDropDown = $currentDropdownTrigger.closest(dropdownMenu.selectors.dropdown);
    var currentMenuLinksSelector = selectors.navbarLevels.join(', ');
    var $currentMenu = $currentDropDown.closest(currentMenuLinksSelector);

    $modalBackground.addClass(CONST.classes.active);
    $currentMenu.addClass(CONST.classes.childExpanded);
    $currentDropDown.addClass(CONST.classes.show);
    $currentMenu.animate({
        scrollTop: 0
    });
    $currentDropdownTrigger.attr(CONST.attributes.ariaExpanded, 'true');
}

/**
 * Handle Tap / Click events on devices where it's not possible to hover
 * But the large screen 'dropdown' menu is visible
 * @param {HtmlEvent} e -
 */
function dropdownTriggerClick(e) {
    var $currentDropdownTrigger = $(this);
    // Prevent first tap for iPad users in landscape mode
    if (cwUtil.isTouch() && !cwUtil.doubleTapElement(this)) {
        e.preventDefault();
    }

    // event is prevented by bootstrap because the element is a bootstrap dropdown element
    // but the user should still be able to go to the top category on desktop
    if (!cwUtil.isTouch()) {
        window.location = $currentDropdownTrigger.attr(CONST.attributes.href);
    }
}

/**
 * Opens / closes mobile menu
 * @param {HtmlEvent} e -
 */
function toggleMobileMenu(e) {
    e.preventDefault();
    window.scrollTo(0, 0);
    var $navBarTriggerIcon = $(selectors.mobileMenuTriggerIcon);

    var menuSelectors = [
        $navBarTriggerIcon,
        $primaryHeader,
        $mainMenu,
        $modalBackground
    ];

    // If the menu has not been opened yet, open it and add classes/elements
    if (!$mainMenu.hasClass(CONST.classes.active)) {
        $doc.trigger(CONST.events.toggleModal, CONST.classes.show);
        $body.trigger(CONST.events.toggleMobileMenu);
        $htmlBody.addClass(CONST.classes.scrollLock);

        menuSelectors.forEach(function (element) {
            element.addClass(CONST.classes.active);
        });
    } else {
        // If the menu is open, close it
        $doc.trigger(CONST.events.toggleModal, CONST.classes.hide);
        $htmlBody.removeClass(CONST.classes.scrollLock);

        menuSelectors.forEach(function (element) {
            element.removeClass(CONST.classes.active);
        });

        $modalBackground.removeAttr(CONST.attributes.style);

        closeAllLevels();
    }

    // CW: Add an animating class for CSS purposes
    $mainMenu.addClass(CONST.states.isAnimating);
    $mainMenu.on(CONST.events.transitionEnd, function () {
        $mainMenu.removeClass(CONST.states.isAnimating);
    });
}

/**
 * 1) Inside the hamburger menu:
 * 2) After clicking anywhere inside the "level-2" submenu:
 * stop propogation to avoid closing animation of the menu when in second level of menu
 * '.nav-level-2' is selectors.navbarLevels[1] as it's not zero based :(
 * @param {HtmlEvent} e -
 */
function onMediumScreenClick(e) {
    e.stopImmediatePropagation();
}

/**
 * Function for triggering a resize on the menu elements.
 * This is necessary because in some versions of Safari, the position of the arrows don't get updated
 * Once you switch resolutions.
 */
function resizeMenuElements() {
    if ($levelOneLinks.length > 0) {
        $levelOneLinks.each(function () {
            cwUtil.rerenderElement($(this));
        });
    }
}

/**
 * Activates / deactivates events for large screens
 * @param {boolean} isBind to bind or unbind events based on passed parameter
 */
function largeScreenEvents(isBind) {
    var bindMethod = isBind ? 'on' : 'off';
    $menuDropdownTriggers[bindMethod](CONST.events.click, dropdownTriggerClick);
    $menuDropdownTriggers[bindMethod](CONST.events.mouseenter, dropdownMenu.setActive);
    $menuDropdownTriggers[bindMethod](CONST.events.mouseleave, dropdownMenu.setInactive);
    $menuDropdownTriggers.parent()[bindMethod](CONST.events.mouseleave, dropdownMenu.setInactive);
}

/**
 * Activates / deactivates events for medium size screens
 * @param {boolean} isBind to bind or unbind events based on passed parameter
 */
function mediumScreenEvents(isBind) {
    var bindMethod = isBind ? 'on' : 'off';
    $doc[bindMethod](CONST.events.click, selectors.navbarLevels[1], onMediumScreenClick);
}

/**
 * Activates / deactivates events for small size screens
 * @param {boolean} isBind to bind or unbind events based on passed parameter
 */
function smallScreenEvents(isBind) {
    var bindMethod = isBind ? 'on' : 'off';
    // Opening a nested level inside mobile menu
    $menuDropdownTriggers[bindMethod](CONST.events.click, openLevel);
    // Showing mobile menu
    if (!$(selectors.mobileMenuTrigger).attr('binded')) {
        $(selectors.mobileMenuTrigger).attr('binded', 'true');
        $body[bindMethod](CONST.events.click, selectors.mobileMenuTrigger, toggleMobileMenu);
    }
    $('.js-navbar-toggler').click(function () {
        $('.js-nav-item-toggler').attr('aria-expanded', 'false');
    });
}

/**
 * Binds / Unbinds events based on screen size
 */
function updateEventListeners() {
    largeScreenEvents(cwUtil.isLargeScreen());
    mediumScreenEvents(cwUtil.isMediumScreen());
    smallScreenEvents(!cwUtil.isLargeScreen());
}

module.exports = function menu() {
    $doc = $(document);
    $htmlBody = $(CONST.selectors.htmlBody);
    $body = $(CONST.selectors.body);
    $primaryHeader = $(selectors.primaryHeader);
    $navBar = $(selectors.navbar);
    $levelOneLinks = $(selectors.navbarLevelOneLinks);
    $mainMenu = $(selectors.mainMenu);
    $menuDropdownTriggers = $mainMenu.find(dropdownMenu.selectors.activeDropdown);
    $modalBackground = $(selectors.modalBackground);

    // set events on load
    updateEventListeners();
    /**
     * Resize Events
     */
    function handleWindowResize() {
        resizeMenuElements();
        updateEventListeners();
    }
    window.addEventListener(CONST.events.resize, cwUtil.debounce(handleWindowResize, 250));
};
