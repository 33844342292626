const CONST = require('plugin_frontend_core/constants');
const cwUtil = require('plugin_frontend_core/utils/utils_cw');

var selectors = {
    body: 'body',
    popover: '.js-popover',
    openBtn: '.js-open-popover',
    closeBtn: '.js-popover-close',
    openChat: '.js-open-chat'
};

function showPopover() {
    $(selectors.popover).addClass('show');
}

function hidePopover() {
    $(selectors.popover).removeClass('show');
}

function bindEvents() {
    $(selectors.openBtn).on('click', function(e){
        e.stopPropagation();
        e.preventDefault();
        showPopover();
    });
    $(selectors.closeBtn).on('click', function(e){
        e.preventDefault();
        hidePopover();
    });
    $(selectors.body).on('click', function(){
        if ($(selectors.popover).hasClass('show')){
            hidePopover();
        }
    });
    $(selectors.openChat).on('click', function(e){
        var $chatBtn = $('.helpButtonEnabled');
        if ($chatBtn.length > 0){
            e.preventDefault();
            $chatBtn.trigger( "click" );
            $(selectors.body).animate({
                scrollTop: $chatBtn.offset().top
            }, 500);
        }
    });
}


module.exports = function headerInit() {
    bindEvents();
};