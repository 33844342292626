const CONST = require('plugin_frontend_core/constants');

/**
* addClassToCurrentSlide
*/
function addClassToCurrentSlide() {
    $(CONST.selectors.slick.all).on({
		beforeChange: function (event, slick, currentSlide, nextSlide) {
			// Generic fix to make sure the current slide has the .slick-current class.
			$(CONST.selectors.slick.slide).removeClass(CONST.classes.slick.currentSlide);
			$(`${CONST.selectors.slick.slide}[data-index=${nextSlide}]`).addClass(CONST.classes.slick.currentSlide);
		}
	});
}

/**
* Initializes the module
*/
function init() {
    addClassToCurrentSlide();
}

module.exports = {
    init: init
};
