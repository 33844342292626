'use strict';

const CONST = require('plugin_frontend_core/constants');

var selectors = {
    loginBtn: '.header-cta-login-btn',
    loginOverlay: '.js-login-overlay'
};
var $loginBtn = $(selectors.loginBtn);
var $loginOverlay = $(selectors.loginOverlay);

function showLoginOverlay() {
    $loginBtn.on(CONST.events.click, function (e) {
        e.preventDefault();
        $loginOverlay.css('display', 'block');
    });
}

function loginOverlaySlider() {
    $loginBtn.on(CONST.events.click, function (e) {
        e.preventDefault();
        $loginOverlay.toggleClass('show');
    });
}

module.exports = function headerInit() {
    var mobileUP = window.matchMedia('(min-width: 768px)');
    var mobileBelow = window.matchMedia('(max-width: 767px)');

    // Login Overlay closing button event for Desktop
    if (mobileUP.matches) {
        showLoginOverlay();
    }
    // Login Overlay closing button event for Mobile
    if (mobileBelow.matches) {
        loginOverlaySlider();
    }
};
