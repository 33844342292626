const debounce = require('lodash/debounce');
const CONST = require('plugin_frontend_core/constants');

const selectors = {
    header: '.js-site-header',
    headerSecondary: '.js-header-secondary',

    mobileMenuTrigger: '.js-navbar-toggler',

    search: '.js-site-search',
    searchField: '.js-search-field',
    inputSearchField: 'input.search-field',
    searchTrigger: '.js-search-trigger',

    searchMobile: '.js-mobile-site-search',
    searchMobileEl: '.js-search-mobile',
    searchQueryPush: '.js-search-query-datalayer',

    suggestionsWrapper: '.js-suggestions-wrapper',
    suggestions: '.js-suggestions'
};

const classes = {
    searchActive: 'search-active',
    searchField: 'js-search-field',
    searchTrigger: 'js-search-trigger',
    searchQueryPush: 'js-search-query-datalayer'
};

// elements that don't get destroyed by innerHTML updates
const $body = $(CONST.selectors.body);
const $form = $(CONST.selectors.form);
const $doc = $(CONST.selectors.document);

const $headerSecondary = $(selectors.headerSecondary);
const $mobileMenuTrigger = $(selectors.mobileMenuTrigger);

const $header = $(selectors.header);
const $search = $(selectors.search);
const $searchField = $(selectors.searchField);
const $searchMobile = $(selectors.searchMobile);
const $searchMobileEl = $(selectors.searchMobileEl);
const $searchTrigger = $(selectors.searchTrigger);
const $searchQueryPush = $(selectors.searchQueryPush);

const $suggestionsWrapper = $(selectors.suggestionsWrapper);
const $suggestions = $(selectors.suggestions);

const endpoint = $suggestionsWrapper.data(CONST.attributes.url);
const minChars = 3;

const preventSubmitByEnterDefault = false;

// Initial value to prevent submit by Enter btn
window.preventSubmitByEnter = preventSubmitByEnterDefault;

// Toggle the order history tab in case of store order search
var searchParams = new URLSearchParams(window.location.search);
if (searchParams.has('storeOrderNo')) {
    $(CONST.selectors.contentSearch).trigger('click');
}

/**
 * Retrieves Suggestions element relative to scope
 *
 * @param {Object} scope - Search input field DOM element
 * @return {JQuery} - .suggestions-wrapper element
 */
function getSuggestionsWrapper(scope) {
    return $(scope).closest($form).find($suggestionsWrapper);
}

/**
 * Determines whether DOM element is inside the .search-mobile class
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 * @return {boolean} - Whether DOM element is inside  div.search-mobile
 */
function isMobileSearch(scope) {
    return !!$(scope).closest($searchMobileEl).length;
}

/**
 * Remove modal classes needed for mobile suggestions
 *
 */
function clearModals() {
    $body.removeClass(CONST.classes.modalOpen);
    $suggestionsWrapper.find(selectors.suggestions).removeClass($(selectors.modal));
}

/**
 * Apply modal classes needed for mobile suggestions
 */
function applyModals() {
    $body.addClass(CONST.classes.modalOpen);
    $suggestionsWrapper.find(selectors.suggestions).addClass($(selectors.modal));
}

/**
 * Tear down Suggestions panel
 */
function tearDownSuggestions() {
    const searchMobileSuggestions = $(selectors.searchMobile + selectors.suggestions);
    $(selectors.inputSearchField).val('');
    clearModals();
    $(searchMobileSuggestions).unbind(CONST.events.scroll);
    $suggestionsWrapper.empty();
    $header.removeClass('hasSuggestions');
}

/**
 * Positions Suggestions panel on page
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function positionSuggestions(scope) {
    const $scope = $(scope);
    const top = $scope.offset().top;
    const outerHeight = $scope.outerHeight();
    $suggestionsWrapper.find($suggestions).css('top', top + outerHeight);
}

/**
 * Process Ajax response for SearchServices-GetSuggestions
 * @param {Function} successCallback - callback to be called as response got success status
 * @param {Object|string} response - Empty object literal if null response or string with rendered
 *                                   suggestions template contents
 */
function processResponse(successCallback, response) {
    $suggestionsWrapper.empty();

    $.spinner().stop();

    if (!(typeof (response) === 'object')) {
        $suggestionsWrapper.append(response).show();
        $header.addClass('hasSuggestions');
        successCallback(true);

        if (isMobileSearch(this)) {
            positionSuggestions(this);
            applyModals();
        }
    } else {
        $suggestionsWrapper.hide();
        $header.removeClass('hasSuggestions');
    }
}

/**
 * Retrieve suggestions
 *
 * @param {Object} scope - Search field DOM element
 * @param {Event} e - ?
 * @param {Function} successCallback - callbackMethod
 */
function getSuggestions(scope, e, successCallback) {
    let searchQuery = $(scope).val();
    if (searchQuery.length >= minChars) {
        $.spinner().start();

        $.ajax({
            context: scope,
            url: endpoint + searchQuery,
            method: 'GET',
            success: processResponse.bind(null, successCallback),
            error: $.spinner().stop
        });
    } else {
        clearModals();
        getSuggestionsWrapper(scope).empty();
    }
}

$(selectors.inputSearchField).each(function () {
    /**
     * Use debounce to avoid making an Ajax call on every single key press by waiting a few
     * hundred milliseconds before making the request. Without debounce, the user sees the
     * browser blink with every key press.
     */
    const debounceSuggestions = debounce(getSuggestions, 300);

    $(this).on(CONST.events.keyup, function (e) {
        // 13 - Enter btn
        var key = e.which;

        if (key !== 13) {
            window.preventSubmitByEnter = preventSubmitByEnterDefault;
            debounceSuggestions(this, e, function (responseSuccess) {
                if (responseSuccess) window.preventSubmitByEnter = false;
            });
        } else {
            window.dataLayer.push({
                event: 'searchQuery',
                searchParameter: $searchField.val(),
                eventLabel: 'enter button'
            });
            // This else condition needed to prevent extra requests by clicking Enter button when first request was sent
            if (window.preventSubmitByEnter) e.preventDefault();
        }
    });
});


$body.on(CONST.events.click, function (e) {
    const $self = $(e.target);
    const isSearchRelatedClick = $self.hasClass(classes.searchField) || $self.hasClass(classes.searchTrigger);
    const $mobileSearchField = $searchMobile.find($searchField);

    const searchSelectors = [
        $search,
        $searchField,
        $mobileSearchField
    ];

    searchSelectors.forEach(function (searchSelector) {
        if (isSearchRelatedClick) {
            // When any element on the body is clicked and its target is either a search-field, search-trigger or mobile search-trigger elements,
            // add 'active' classes for styling purposes
            searchSelector.addClass(CONST.classes.active);
        } else {
            // When any element on the body is clicked and its target is anything but a search-field, search-trigger or mobile search-trigger element,
            // remove 'active' classes for styling purposes
            searchSelector.removeClass(CONST.classes.active);
        }
    });

    if (!isSearchRelatedClick) {
        $suggestionsWrapper.find($suggestions).hide();
        tearDownSuggestions();
    }
});

$body.on(CONST.events.toggleMobileMenu, function () {
    if ($headerSecondary.hasClass(classes.searchActive)) {
        $headerSecondary.removeClass(classes.searchActive);
    }
});

$body.on(CONST.events.click, $searchQueryPush, function (event) {
    if (event.target.className.indexOf(classes.searchQueryPush) > -1) {
        const searchQuery = event.target.getAttribute('data-searchquery');
        if (searchQuery) {
            window.dataLayer.push({
                event: 'searchQuery',
                searchParameter: searchQuery,
                eventLabel: event.target.innerText
            });
        }
    }
});

module.exports = {};
