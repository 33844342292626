const CONST = require('plugin_frontend_core/constants');

const selectors = {
    productCarousel: '.js-product-carousel-container:not(.slick-initialized)',
    cQuotientProductCarousel: '.js-cquotient-noanchor-product-carousel:not(.slick-initialized)',
    productCarouselItem: '.js-product-carousel-item',
    cQuotientProductCarouselContainer: '.product-carousel-einstein'
};

const slickConfigProductCarousel = {
    slidesToShow: 5,
    slidesToScroll: 5,
    arrows: true,
    dots: true,
    fade: false,
    rows: 0,
    speed: 1000,
    slide: selectors.productCarouselItem,
    responsive: [{
        breakpoint: CONST.breakpoints.lg, // For breakpoints smaller than 'lg'
        settings: {
            slidesToShow: 4,
            slidesToScroll: 4
        }
    },
        {
            breakpoint: CONST.breakpoints.md, // For breakpoints smaller than 'md'
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

/**
 * initCarousels
 */
function initCarousels() {
    const $productCarousel = $(selectors.productCarousel);

    if ($productCarousel.length > 0) {
        $productCarousel.slick(slickConfigProductCarousel);
    }
}

let cqItvIndex = 0;
let CQuotientInterval;
/**
 * initCQuotient
 * Einstein adds recommendations client side after dom load. Since there is no callback from this that we can use, we need
 * to set an interval to check when it has been added.
 */
function initCQuotient() {
    initCarousels();

    $('body').on('einstein:loaded', function (event, data) {
        var $element = $(data.parent);
        var $container = $element.closest(selectors.cQuotientProductCarouselContainer);
        $element.slick(slickConfigProductCarousel);
        $container.removeClass('d-none');
        $('body').trigger('einstein:loaded:gtm');
    });

    cqItvIndex = 0;
    clearInterval(CQuotientInterval);

    CQuotientInterval = setInterval(function CQuotientIntervalCheck() {
        cqItvIndex++;
        const $productCarousel = $(selectors.productCarousel);

        if ($productCarousel.length > 0) {
            initCarousels();
            clearInterval(CQuotientInterval);
            return;
        }

        if (cqItvIndex === 10) {
            clearInterval(CQuotientInterval);
        }
    }, 500);
}

/**
 * Initializes the file
 */
function init() {
    $(document).ready(initCQuotient);
}

module.exports = {
    init: init
};
