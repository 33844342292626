const CONST = require('plugin_frontend_core/constants');

const selectors = {
    modal: '.js-modal',
    modalDialog: '.js-modal-dialog',
    modalClose: '.js-modal-close',
    modalHeader: '.js-modal-header',
    modalBody: '.js-modal-body',
    modalBackdrop: '.js-modal-backdrop'
};

const classes = {
    modalOpen: 'custom-modal-open',
    modalWide: 'modal--wide'
};

const modals = {
    htmlModal: '[data-toggle="html-modal"]',
    imgModal: '[data-toggle="img-modal"]',
    urlModal: '[data-toggle="url-modal"]'
};

const modalTypes = {
    html: 'html',
    img: 'img',
    url: 'url'
};

const attributes = {
    modalTitle: 'modal-title',
    modalClass: 'modal-class'
};

const $bodyEl = $(CONST.selectors.body);

/**
 * Renders a modal element
 * @param {*} modalObject - Object with modal information
 */
function renderModal(modalObject) {
    if (!modalObject) {
        return;
    }

    const randomString = (Math.round(Math.random() * Number.MAX_SAFE_INTEGER)).toString(36);
    const modalId = 'modal_' + randomString;
    const labelId = modalId + 'label';
    const modalHTML =
        '<div class="modal js-modal fade" id="' + modalId + '" tabindex="0"' +
            'role="dialog" aria-labelledby="' + labelId + '">' +
            '<div class="modal-dialog js-modal-dialog" role="document">' +
                '<div class="modal-content">' +
                    '<div class="modal-header js-modal-header">' +
                        '<h5 class="modal-title" id="' + labelId + '"></h5>' +
                        '<span type="button" class="modal-close icon icon--close js-modal-close" data-dismiss="modal" aria-label="Close"></span>' +
                    '</div>' +
                    '<div class="modal-body js-modal-body"></div>' +
                '</div>' +
            '</div>' +
        '</div>';

    if ($bodyEl.hasClass(classes.modalOpen)) {
        return;
    }

    const $modal = $(modalHTML);
    const $modalBody = $modal.find(selectors.modalBody);

    if (modalObject.type === modalTypes.html) {
        $modalBody.html(modalObject.content);
    } else if (modalObject.type === modalTypes.img) {
        $modalBody.html('<img src="' + modalObject.content + '" alt="" />');
    } else if (modalObject.type === modalTypes.url) {
        $.ajax({
            url: modalObject.content
        }).done(function (response) {
            $modalBody.append(response);
        }).fail(function () {
            console.error('Create modal: External url does not exist');     // eslint-disable-line
            return;
        });
    } else {
        console.error('Create modal: Trying to use an unsupported type');   // eslint-disable-line
        return;
    }

    if (modalObject.title) {
        $modal.find('#' + labelId).text(modalObject.title);
    } else {
        $modalBody.prepend($modal.find(selectors.modalClose).eq(0).prop('outerHTML'));
        $modal.find(selectors.modalHeader).remove();
    }

    if (modalObject.class) {
        $modal.addClass(modalObject.class);
    }

    $modal.addClass('modal--' + modalObject.type);

    $modal.find(selectors.modalClose).on(CONST.events.click, function () {
        $bodyEl.removeClass(classes.modalOpen);
        $modal.fadeOut(function () {
            $bodyEl.find(selectors.modalBackdrop).remove();
            $modal.remove();
        });
    });

    let $placeModalTarget;

    if (modalObject.target) {
        $placeModalTarget = modalObject.target;
    } else {
        $placeModalTarget = modalObject.initializer;
    }

    $bodyEl.addClass(classes.modalOpen);
    $placeModalTarget.after($modal);

    $modal.modal(CONST.classes.show);
    modalObject.initializer.trigger({
        type: 'custom-modal-opened',
        $element: $modal
    });
}

/**
 * Creates a modal element
 * @param {*} $self - modal target
 * @param {*} modalType - defines the type of modal
 * @param {*} modalTitle - defines the title for the modal
 * @param {*} modalClass - defines any custom classes for the modal
 * @param {*} modalTarget - modal target
 */
function createModal($self, modalType, modalTitle, modalClass, modalTarget) {
    const $target = $(modalTarget);

    let modalObject = {};

    modalObject.type = modalType;
    modalObject.initializer = $self;

    if (modalType === modalTypes.html) {
        if (!$target[0]) {
            console.error('Modal custom: target not found');                // eslint-disable-line
            return;
        }

        modalObject.target = $target;
        modalObject.content = $target.html();
    } else if (modalObject.type === modalTypes.img) {
        modalObject.content = $self.attr(CONST.attributes.href);
        modalObject.modalClass = classes.modalWide;
    } else if (modalObject.type === modalTypes.url) {
        modalObject.content = $self.attr(CONST.attributes.href);
    }

    if (modalTitle) {
        modalObject.title = modalTitle;
    }

    if (modalClass) {
        if (modalObject.class !== undefined) {
            modalObject.class += ' ' + modalClass;
        } else {
            modalObject.class = modalClass;
        }
    }

    renderModal(modalObject);
}

/**
* goToModalPane
* @param {event} event - The event
*/
function goToModalPane(event) {
    const $modalPaneContainer = $(event.target);
    const paneDirection = event.type;

    if (!$modalPaneContainer.length || !paneDirection) {
        console.error('fn goToModalPane: insufficient data', event);
        return;
    }

    const $panes = $modalPaneContainer.find(CONST.selectors.modalPanes.pane);
    const $activePane = $panes.filter(CONST.selectors.modalPanes.activePane);
    let $newPane;

    if (paneDirection === CONST.events.modalPanes.next) {
        $newPane = $activePane.next(CONST.selectors.modalPanes.pane);
    } else if (paneDirection === CONST.events.modalPanes.prev) {
        $newPane = $activePane.prev(CONST.selectors.modalPanes.pane);
    } else {
        console.error('fn goToModalPane: expected a direction in the event', event);
        return;
    }

    if ($newPane.length) {
        $activePane.removeClass(CONST.classes.modalPanes.activePane);
        $newPane.addClass(CONST.classes.modalPanes.activePane);
    } else {
        console.error('fn goToModalPane: pane not found');
    }
}

/**
 * Initializes the module
 */
function init() {
    $bodyEl.on(CONST.events.click, modals.htmlModal, function (event) {
        event.preventDefault();

        const $self = $(this);
        const modalType = modalTypes.html;
        const modalTitle = $self.data(attributes.modalTitle);
        const modalClass = $self.data(attributes.modalClass);
        const modalTarget = $self.data(CONST.attributes.target);

        createModal($self, modalType, modalTitle, modalClass, modalTarget);
    });

    $bodyEl.on(CONST.events.click, modals.urlModal, function (event) {
        event.preventDefault();

        const $self = $(this);
        const modalType = modalTypes.url;
        const modalTitle = $self.data(attributes.modalTitle);
        const modalClass = $self.data(attributes.modalClass);

        createModal($self, modalType, modalTitle, modalClass, null);
    });

    $bodyEl.on(CONST.events.click, modals.imgModal, function (event) {
        event.preventDefault();

        const $self = $(this);
        const modalType = modalTypes.img;
        const modalTitle = $self.data(attributes.modalTitle);
        const modalClass = $self.data(attributes.modalClass);

        createModal($self, modalType, modalTitle, modalClass, null);
    });

    $(CONST.selectors.modalPanes.container).on(CONST.events.modalPanes.next, goToModalPane);
    $(CONST.selectors.modalPanes.container).on(CONST.events.modalPanes.prev, goToModalPane);

    // Custom backdrop for My Account Redesign 2024
    $('.modal').on('shown.bs.modal', function (e) {
        if ( $('.modal-backdrop').length > 0 && $('.my-account').length > 0 ) {
            $('.modal-backdrop').addClass('my-account');
        }
    });
}

module.exports = {
    init: init
};
