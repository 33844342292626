'use strict';

const CONST = require('plugin_frontend_core/constants');

const selectors = {
    wishlist: {
        productContainer: '.js-product',
        quickviewActiveAttribute: '.js-product-quickview-slide.slick-current a',
        trigger: '.js-add-to-wishlist'
    },
    quicklink: {
        badge: '.js-quicklink-badge',
        wishlist: '.js-quicklink-wishlist'
    },
    loginModal: '.js-login-modal'
};

const classes = {
    wishlist: {
        added: 'added-to-wishlist',
        inWishlist: 'is-in-wishlist'
    }
};

const attributes = {
    urlGetList: 'data-url-getlist',
    loginModal: 'data-href-loginmodal'
};

/**
 * Displays a message for the wishlist
 * @param {Object} config for the notification
 */
function displayMessage(config) {
    $.spinner().stop();
    let status;

    if (!config || !config.data) {
        console.error('Wishlist fn displayMessage: insufficient data');
        return;
    }

    const data = config.data;
    const button = config.target;

    if (data.success) {
        status = 'success';
    } else {
        status = 'error';
    }

    window.pubSub.publish(CONST.pubSubEvents.showNotification, {
        result: status,
        msg: data.msg
    });

    if (button && button.length) {
        button.removeAttr(CONST.attributes.disabled);
    }
}

/**
 * Updates badges which need to show the amount of items in a wishlist
 * @param {Object} config for the notification
 */
function updateWishlistAmount(config) {
    var $wishlistLink = $(selectors.quicklink.wishlist).eq(0);

    if (!$wishlistLink.length) {
        console.error('Wishlist fn updateWishlistAmount: no wishlistLink found');
        return;
    }

    var $badge = $wishlistLink.find(selectors.quicklink.badge).eq(0);
    var urlGetList = $wishlistLink.attr(attributes.urlGetList);

    if (!$badge.length || !urlGetList) {
        console.error('Wishlist fn updateWishlistAmount: no badge or url found');
        return;
    }

    $.ajax({
        url: urlGetList,
        type: 'get',
        dataType: 'json',
        success: function (data) {
            $badge.text(data.list.length);

            if (config.addedToWishlist) {
                $wishlistLink.addClass(classes.wishlist.added);

                setTimeout(function () {
                    $wishlistLink.removeClass(classes.wishlist.added);
                }, CONST.times.heartTransitionTime);
            }

            if (data.list.length > 0) {
                $badge.removeClass(CONST.classes.dNone);
            } else {
                $badge.addClass(CONST.classes.dNone);
            }
        },
        error: function (err) {
            console.error('Wishlist fn updateWishlistAmount', err);
        }
    });
}

/**
 * Add product to wishlist
 * @param {Object} addToWishlistData - The product data
 */
function addToWishlist(addToWishlistData) {
    if (!addToWishlistData ||
        !('$allTriggers' in addToWishlistData) ||
        !('$productContainer' in addToWishlistData) ||
        !('isInWishlist' in addToWishlistData) ||
        !('pid' in addToWishlistData) ||
        !('urlAdd' in addToWishlistData) ||
        !('urlRemove' in addToWishlistData)
    ) {
        console.error('Wishlist fn addToWishlist: insufficient data', addToWishlistData);
        return;
    }

    if (!addToWishlistData.isInWishlist) {
        addToWishlistData.$allTriggers.addClass(classes.wishlist.added);

        setTimeout(function () {
            addToWishlistData.$allTriggers.removeClass(classes.wishlist.added);
        }, CONST.times.heartTransitionTime);
    }

    $.spinner().start();
    $.ajax({
        url: addToWishlistData.isInWishlist ? addToWishlistData.urlRemove : addToWishlistData.urlAdd,
        type: addToWishlistData.isInWishlist ? 'get' : 'post',
        dataType: 'json',
        data: {
            pid: addToWishlistData.pid,
            optionId: addToWishlistData.optionId,
            optionVal: addToWishlistData.optionVal
        },
        success: function (data) {
            window.pubSub.publish(CONST.pubSubEvents.wishlist.displayMessage, {
                data: data,
                target: addToWishlistData.$allTriggers
            });

            if (addToWishlistData.notAuthenticated) {
                window.location.href = window.location.href;
            } else {
                window.pubSub.publish(CONST.pubSubEvents.wishlist.updateAmount, {
                    addedToWishlist: !addToWishlistData.isInWishlist
                });

                if (addToWishlistData.isInWishlist) {
                    addToWishlistData.$allTriggers.removeClass(classes.wishlist.inWishlist);
                } else {
                    addToWishlistData.$allTriggers.addClass(classes.wishlist.inWishlist);
                    addToWishlistData.$allTriggers.addClass(classes.wishlist.added);
                }

                // In case we're in the product tile quickview, update the 'is in wishlist' attribute for product attributes such as size
                const $quickviewActiveAttribute = addToWishlistData.$productContainer.find(selectors.wishlist.quickviewActiveAttribute);

                if ($quickviewActiveAttribute.length > 0) {
                    $quickviewActiveAttribute.attr(CONST.attributes.product.isInWishlist, !addToWishlistData.isInWishlist);
                }
            }
        },
        error: function (err) {
            window.pubSub.publish(CONST.pubSubEvents.wishlist.displayMessage, {
                data: err,
                target: addToWishlistData.$allTriggers
            });
        }
    });
}

/**
 * Event handler for add a product to wishlist
 * @param {Object} wishlistClickData - The product data
 */
function clickAddToWishlistHandler(wishlistClickData) {
    if (!wishlistClickData ||
        !('trigger' in wishlistClickData) ||
        !('pid' in wishlistClickData) ||
        !('urlAdd' in wishlistClickData) ||
        !('urlRemove' in wishlistClickData)
    ) {
        console.error('Wishlist fn clickAddToWishlistHandler: insufficient data', wishlistClickData);
        return;
    }
    var customerAuthenticated = window.sfStates.customer.isLoggedIn;
    const $trigger = $(wishlistClickData.trigger);
    const $productContainer = $trigger.closest(selectors.wishlist.productContainer);

    if ($trigger.attr(CONST.attributes.disabled)) {
        return;
    }
    const $allTriggers = $productContainer.find(selectors.wishlist.trigger);
    const addToWishlistData = {
        $allTriggers: $allTriggers,
        $productContainer: $productContainer,
        isInWishlist: $trigger.hasClass(classes.wishlist.inWishlist),
        urlAdd: wishlistClickData.urlAdd,
        urlRemove: wishlistClickData.urlRemove,
        pid: wishlistClickData.pid,
        optionId: wishlistClickData.optionId || null,
        optionVal: wishlistClickData.optionVal || null
    };

    if (!customerAuthenticated) {
        addToWishlistData.notAuthenticated = true;
        $trigger.addClass(CONST.selectors.account.notAuthenticated);
        window.addToWishlistData = addToWishlistData;


        var loginModalURL = $trigger.attr(attributes.loginModal);
        var $modal = $(selectors.loginModal);

        if ($modal.length > 0) {
            // Move the modal to the closest column. Otherwise, if the modal is in a
            // different part of the DOM if will cause a "scroll to the modal" effect
            let $closestColumn = $trigger.closest(CONST.selectors.column);
            if ($closestColumn.is(CONST.selectors.slick.slide)) {
                $closestColumn = $closestColumn.parent().closest(CONST.selectors.column);
            }
            $modal.appendTo($closestColumn);
            $modal.modal({
                focus: false
            });
        } else {
            $.spinner().start();
            $.get(loginModalURL).then(resp => {
                $(CONST.selectors.body).append(resp);
                $.spinner().stop();

                var $newModal = $(selectors.loginModal);
                // Move the modal to the closest column. Otherwise, if the modal is in a
                // different part of the DOM if will cause a "scroll to the modal" effect
                let $closestColumn = $trigger.closest(CONST.selectors.column);
                if ($closestColumn.is(CONST.selectors.slick.slide)) {
                    $closestColumn = $closestColumn.parent().closest(CONST.selectors.column);
                }
                $newModal.appendTo($closestColumn);
                $newModal.modal({
                    focus: false
                });
                $(document).trigger(CONST.events.loginModalShown);
            });
        }
    } else {
        $allTriggers.attr(CONST.attributes.disabled, true);
        addToWishlist(addToWishlistData);
    }
}

/**
 * Initialize the module
 */
function init() {
    window.pubSub.subscribe(CONST.pubSubEvents.wishlist.displayMessage, displayMessage);
    window.pubSub.subscribe(CONST.pubSubEvents.wishlist.updateAmount, updateWishlistAmount);
    window.pubSub.subscribe(CONST.pubSubEvents.wishlist.clickAddToWishlist, clickAddToWishlistHandler);
    window.pubSub.subscribe(CONST.pubSubEvents.wishlist.addToWishlist, addToWishlist);
}

init();
