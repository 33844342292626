const CONST = require('plugin_frontend_core/constants');

/**
 * @namespace cartHelpers
 */

/* eslint-disable no-use-before-define */
// JVP @TODO: Move/merged appendToUrl to cw_util
module.exports = {
    appendToUrl: appendToUrl,
    createErrorNotification: createErrorNotification,
    toggleDisabledStateOnIncreaseButtons: toggleDisabledStateOnIncreaseButtons,
    toggleDisabledStateOnIncreaseButton: toggleDisabledStateOnIncreaseButton,
    updateApproachingDiscounts: updateApproachingDiscounts,
    updateAvailability: updateAvailability,
    updateCartTotals: updateCartTotals,
    validateBasket: validateBasket
};
/* eslint-enable */

/**
 * appends params to a url
 * @memberof cartHelpers
 * @public
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    let newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return `${key}=${encodeURIComponent(params[key])}`;
    }).join('&');

    return newUrl;
}

/**
 * re-renders the order totals and the number of items in the cart
 * @memberof cartHelpers
 * @public
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    const errorHtml = `
        <div class="alert alert-danger alert-dismissible valid-cart-error fade show" role="alert">
            <button type="button" class="btn-close close" data-dismiss="alert" aria-label="${window.sfResources.close}">
                <span aria-hidden="true">${window.sfResources.close}</span>
            </button>
            ${message}
        </div>`;
    $('.cart-error').append(errorHtml);
}

/**
 * Does what it says it does
 * @param {boolean} shouldDisable - should they?
 * @param {HTMLElement} trigger to disable, otherwise search whole document for the trigger
 * @returns {void}
 */
function toggleDisabledStateOnIncreaseButton(shouldDisable, trigger) {
    const $increaseButtons = $(trigger);
    const $qty = $increaseButtons.closest('.quantity-form').find('.quantity');
    const currQty = parseInt($qty.val(), 10);
    const maxQty = $qty.data('qty-max');
    const basketMaxQty = $qty.data('basket-max');

    if ($increaseButtons.length < 1) {
        return;
    }

    if (shouldDisable) {
        $increaseButtons.attr(CONST.attributes.disabled, CONST.attributes.disabled);
    } else if (currQty !== maxQty || !basketMaxQty) {
        $increaseButtons.removeAttr(CONST.attributes.disabled, CONST.attributes.disabled);
    }
}

/**
 * Does what it says it does
 * @param {boolean} shouldDisable - should they?
 * @returns {void}
 */
function toggleDisabledStateOnIncreaseButtons(shouldDisable) {
    const $increaseButtons = $('.js-quantity-changer[data-direction="increase"]');

    if ($increaseButtons.length < 1) {
        return;
    }

    $.each($increaseButtons, (i, element) => {
        toggleDisabledStateOnIncreaseButton(shouldDisable, element);
    });
}

/**
 * re-renders the approaching discount messages
 * @memberof cartHelpers
 * @public
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    let html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(item => {
            html += `<div class="single-approaching-discount text-center">${item.discountMsg}</div>`;
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * Updates the availability of a product line item
 * @memberof cartHelpers
 * @public
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    let lineItem;
    let messages = '';

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    $('.availability-' + lineItem.UUID).empty();

    if (lineItem.availability && lineItem.availability.inStockDate) {
        if (lineItem.availability.messages) {
            lineItem.availability.messages.forEach(function (message) {
                messages += `<p class="line-item-attributes">${message}</p>`;
            });
        }

        messages += `<p class="line-item-attributes line-item-instock-date">${lineItem.availability.inStockDate}</p>`;
    }

    $('.availability-' + lineItem.UUID).html(messages);
}

/**
 * re-renders the order totals and the number of items in the cart
 * @public
 * @memberof cartHelpers
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    const giftCertificateItemData = (data && data.giftCertificateItems && data.giftCertificateItems.length > 0) ? data.giftCertificateItems[0] : null;

    const $shippingCostPrice = $('.shipping-cost .js-price');

    $('.js-number-of-items').empty().append(data.numItems);
    $('.order-subtotal .js-price').empty().append(data.totals.subTotal.formatted);
    $('.tax-total .js-price').empty().append(data.totals.totalTax.formatted);
    $('.grand-total .js-price').empty().append(data.totals.grandTotalWithShipping.formatted);
    $('.minicart .sub-total').empty().append(data.totals.subTotal.formatted);
    $('.minicart-quantity').empty().append(data.numItems);

    if (giftCertificateItemData && giftCertificateItemData.totalPrice) {
        $('.js-giftcard-price-value').empty().append(giftCertificateItemData.totalPrice);
    }

    if (data.totals.totalShippingCost.value > 0) {
        $shippingCostPrice.empty().append(data.totals.totalShippingCost.formatted);
        $shippingCostPrice.parent().removeClass('order-totals-value--free');
    } else {
        if (!$shippingCostPrice[0]) {
            return;
        }

        const resourceFree = $shippingCostPrice.attr('data-resource-free');
        $shippingCostPrice.parent().addClass('order-totals-value--free');
        $shippingCostPrice.empty().append(resourceFree);
    }

    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount-total').removeClass(CONST.classes.dNone);
        $('.order-discount-total .js-price').empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $('.order-discount-total').addClass(CONST.classes.dNone);
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        const $shippingDiscountTotal = $('.shipping-discount-total');
        $shippingDiscountTotal.removeClass(CONST.classes.dNone);
        $shippingDiscountTotal.find('.js-price').empty().append(`- ${data.totals.shippingLevelDiscountTotal.formatted}`);
    } else {
        $('.shipping-discount-total').addClass(CONST.classes.dNone);
    }

    data.items.forEach(item => {
        $('.item-' + item.UUID).empty().append(item.renderedPromotions);
        $('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);
    });
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @memberof cartHelpers
 * @public
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error && data.valid.message) {
        $('.checkout-btn').addClass(CONST.classes.disabled);
        createErrorNotification(data.valid.message);
    } else if (data.valid.error && !data.valid.message) {
        $('.checkout-btn').addClass(CONST.classes.disabled);
        $('.cart').empty().append(`
            <div class="row">
                <div class="col-12 text-center">
                    <h1>${data.resources.emptyCartMsg}</h1>
                </div>
            </div>`
        );
        $('.number-of-items').empty().append(data.resources.numberOfItems);
        $('.minicart-quantity').empty().append(data.numItems);
        $('.minicart .popover').empty().removeClass(CONST.classes.show);
    } else {
        $('.checkout-btn').removeClass(CONST.classes.disabled);
    }
}
