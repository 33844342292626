const CONST = require('plugin_frontend_core/constants');
const cwUtil = require('plugin_frontend_core/utils/utils_cw');

var selectors = {
    body: 'body',
    header: '.js-site-header',
    mainNav: '.js-main-menu'
};

function setHeaderElmHeights() {
    var headerHeight = $(selectors.header).outerHeight() || 170;
    $(selectors.body).css( 'padding-top', headerHeight);
    $(selectors.mainNav).css('top', headerHeight);
}

module.exports = function headerInit() {
    setHeaderElmHeights();
    function handleWindowResize() {
        setHeaderElmHeights();
    }
    window.addEventListener(CONST.events.resize, cwUtil.debounce(handleWindowResize, 250));
};