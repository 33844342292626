const CONST = require('plugin_frontend_core/constants');

var selectors = {
    header: '.js-site-header',
    primaryHeader: '.js-primary-header',
    dropdown: '.js-dropdown',
    activeDropdown: '.js-dropdown:not(.disabled) [data-toggle="dropdown"]',
    dropdownMenu: '.js-dropdown-menu', // @TODO LROS: should we delete this, as it is unused?
    dropdownContent: '.js-dropdown-content'
};

// var $headerEl = $(selectors.header); // @TODO LROS: commented out on purpose; styles are not nice, discuss with Tymen
var $dropdownContent = $(selectors.dropdownContent);

/**
 * Resets Bootstrap dropdowns in a given context
 * @param {Jquery.element} $context - current context
 * @param {string} dropdownSelector of dropdowns to reset
 * @returns {void}
 */
function reset($context, dropdownSelector) {
    var $activeDropDown = $context.closest(selectors.dropdownMenu);

    if ($activeDropDown.hasClass(CONST.classes.show)) {
        $activeDropDown.children(dropdownSelector).attr(CONST.attributes.expanded, 'false');
        $activeDropDown.removeClass(CONST.classes.show);
    }
}

/**
 * Finds closest parent that's a dropdown and toggles to active state
 * Assumes a parent list item with an active class applied
 * @param {boolean} isActive -
 * @returns {function} curryable function for activating / deactivating dropdown
 */
function toggleActive(isActive) {
    return function () {
        var $dropdown = $(this);

        $dropdown.parent()[isActive ? 'addClass' : 'removeClass'](CONST.classes.show);
        $dropdown.parent().attr(CONST.attributes.expanded, isActive.toString());

        if ($dropdown.parent().hasClass(CONST.classes.show)) {
            $dropdownContent.addClass(CONST.classes.show);
            // $headerEl.addClass(CONST.classes.expanded); // @TODO LROS: commented out on purpose; styles are not nice, discuss with Tymen
        } else {
            $dropdownContent.removeClass(CONST.classes.show);
            // $headerEl.removeClass(CONST.classes.expanded); // @TODO LROS: commented out on purpose; styles are not nice, discuss with Tymen
        }
    };
}

module.exports = {
    selectors: selectors,
    reset: reset,
    setActive: toggleActive(true),
    setInactive: toggleActive(false)
};
