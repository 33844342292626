const CONST = require('plugin_frontend_core/constants');

const selectors = {
    bannerCarousel: '.js-carousel-container',
    bannerCarouselItem: '.js-carousel-item'
};

const slickConfig = {
    rows: 0,
    slide: selectors.bannerCarouselItem
};

/**
* Initializes the file
*/
function init() {
    $(selectors.bannerCarousel + CONST.selectors.slick.notInitialized).each((index, element) => {
        const $element = $(element);
        const siblingsVisible = $element.data('slides-visible');
        const siblingsVisibleMobile = $element.data('slides-visible-mobile') ? $element.data('slides-visible-mobile') : $element.data('slides-visible');
        const centerMode = $element.data('align-center');
        const slidesToScroll = $element.data('scroll-slides');
        const infiniteLoop = $element.data('infinite-loop');
        const showDots = $element.data('show-dots');
        const mainPadding = $element.data('main-padding');
        const config = siblingsVisible ? {
            ...slickConfig,
            infinite: infiniteLoop,
            centerMode: centerMode,
            centerPadding: mainPadding,
            slidesToShow: siblingsVisible,
            slidesToScroll: slidesToScroll,
            dots: showDots,
            responsive: [
                {
                  breakpoint: 426,
                  settings: {
                    slidesToShow: siblingsVisibleMobile
                  }
                }
              ]
        } : slickConfig;
        $element.slick(config);
    });
}

module.exports = {
    init: init
};
