const CONST = require('plugin_frontend_core/constants');

const selectors = {
    accordionContainer: '.js-accordion-container',
    accordionItem: '.js-accordion-item',
    trigger: '.js-accordion-trigger'
};

const classes = {
    accordionActive: 'accordion--active'
};

/**
 * @private accordion
 * @param {Event} event - click event
 */
function accordion(event) {
    const $trigger = $(event.currentTarget);
    const $target = $trigger.find(selectors.accordionItem);

    if ($target.hasClass(CONST.classes.show)) {
        return;
    }

    $(selectors.accordionItem).each(function (index, el) {
        const $element = $(el);

        if ($element.hasClass(CONST.classes.show)) {
            $element.collapse(CONST.events.hide);
            $element.closest(selectors.trigger).removeClass(classes.accordionActive);
        } else {
            $element.collapse(CONST.events.show);
            $element.closest(selectors.trigger).addClass(classes.accordionActive);
        }
    });
}

$(document).on(CONST.events.click, selectors.trigger, accordion);
