exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/**\n * CSS Font family names\n *\n * usage:\n * font-family: font-family(primary)\n * font: 12px/24px font-family(secondary)\n */\n/**\n * Font types, define font styling, optionally combined with breakpoints\n * retrieve font style with the mixin: font-type($name)\n *\n * usage:\n * @include font-type(button)\n */\n", ""]);

// exports
exports.locals = {
	"breakpoint_xs": "0px",
	"breakpoint_sm": "520px",
	"breakpoint_md": "720px",
	"breakpoint_lg": "992px",
	"breakpoint_xl": "1200px"
};