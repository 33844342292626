'use strict';

const CONST = require('plugin_frontend_core/constants');

const selectors = {
    headerUsps: '.js-header-usps'
};

/**
 * Initializes USPs in the header and allows them to loop
 */
function initUsps() {
    var $uspList = $(selectors.headerUsps);
    var $uspListItem = $uspList.find(CONST.selectors.listItem);
    var totalItems = $uspListItem.length;

    // @TODO LROS: export these into shared variables
    var cssTransitionTime = 1000;
    var intervalTime = 5000;
    var index = 0;

    $uspListItem.eq(index).addClass(CONST.classes.active);

    setInterval(function () {
        if ((index + 1) < totalItems) {
            index++;
        } else {
            index = 0;
        }

        $uspListItem.removeAttr(CONST.attributes.class);

        setTimeout(function () {
            $uspListItem.eq(index).addClass(CONST.classes.active);
        }, cssTransitionTime);
    }, intervalTime);
}

module.exports = {
    initUsps: initUsps
};
