/* eslint-disable require-jsdoc */
'use strict';

const Masonry = require('masonry-layout');

const selectors = {
    masonryGrid: '.grid',
    masonryGridItem: '.grid-item',
};

const masonryGrid = document.querySelector(selectors.masonryGrid);

function createMasonryGrid() {
    if (!masonryGrid) {
        return;
    }

    const masonryOptions = {
        columnWidth: 340,
        itemSelector: selectors.masonryGridItem,
        horizontalOrder: true
    };

	// Create the grid
    const masonry = new Masonry(masonryGrid, { masonryOptions });

	// For debugging purposes, leaving this line commented out for the future
    // masonry.on('layoutComplete', () => console.log("Masonry active"));
}

function initGrid() {
    window.addEventListener('load', createMasonryGrid);
}

module.exports = {
    init: initGrid
};
