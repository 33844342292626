'use strict';

const CONST = require('../constants');

var selectors = {
    cookieLength: 'cookielength',
    cookieHide: 'cookiehide'
};

var classes = {
    visuallyHidden: 'visually-hidden'
};

var attributes = {
    cookieLength: '[data-cookielength]',
    cookieHide: '[data-cookiehide]'
};

var cookieValues = {
    hide: 'hide'
};

/**
 * Init function
 */
function init() {
    var self = this;

    $(function () {
        $(attributes.cookieLength).each(self.checkCookie);
    });

    $(document).on(CONST.events.click, attributes.cookieHide, self.activateCookie);
}

/**
 * Gets a cookie
 * @param {string} cname - Name of the cookie
 * @returns {string} - Returns the value of the cookie if it exists. Otherwise it returns an empty string
 */
function getCookie(cname) {
    var name = cname + '=';
    var ca = document.cookie.split(';');

    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1);
        if (c.indexOf(name) !== -1) {
            return c.substring(name.length, c.length);
        }
    }

    return '';
}

/**
 * Sets a cookie
 * @param {string} cname - Name of the cookie
 * @param {string} cvalue - Value of the cookie
 * @param {number} exdays - Amount of days the cookie needs to exists
 * @param {string} [domain] - Optional domain to set cookie for
 */
function setCookie(cname, cvalue, exdays, domain) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));

    var expires = 'expires=' + d.toGMTString();

    // Removed domain from cookie string, defaults to current domain
    var domainAndPath = `${domain ? 'domain=' + domain : ''}; path=/; secure; sameSite=None`;
    var cookieString = cname + '=' + cvalue + '; ' + expires + domainAndPath;
    document.cookie = cookieString;
}

/**
 * Deletes a cookie
 * @param {string} cname - Name of the cookie
 * @param {string} [domain] - Optional domain to set cookie for
 */
function deleteCookie(cname, domain) {
    const passedDate = new Date(0);
    const expires = `expires=${passedDate.toGMTString()}`;
    var domainAndPath = `${domain ? 'domain=' + domain : ''}; path=/; secure; sameSite=None`;

    document.cookie = `${cname}=;${expires}${domainAndPath}`;
}

/**
 * Checks a cookie
 */
function checkCookie() {
    var $self = $(this);
    var cookieName = $self.data(selectors.cookieHide);
    var $container = $('#' + cookieName);

    if (self.getCookie(cookieName).length === 0 && $container[0]) {
        $container.removeClass(classes.visuallyHidden);
    } else {
        $container.remove();
    }
}

/**
 * Activates a cookie
 * @returns {Object} setCookie - Calls setCookie function
 */
function activateCookie() {
    var $self = $(this);
    var cookieName = $self.data(selectors.cookieHide);
    var cookieLength = $self.data(selectors.cookieLength);

    return setCookie(cookieName, cookieValues.hide, cookieLength);
}

module.exports = {
    init: init,
    getCookie: getCookie,
    setCookie: setCookie,
    checkCookie: checkCookie,
    activateCookie: activateCookie,
    deleteCookie: deleteCookie
};
