'use-strict';

const selectors = {
    forms: 'form:not([name="simpleSearch"])'
};

/**
 * @function getRelevantInputFields
 * @private
 * @description Receives an array of input fields, and checks them against inputs to ignore and returns an array with
 * inputs to ignore removed.
 * @param {Array} inputFields - An array of input fields to check against types to ignore.
 * @returns {Array} A filtered array of input fields with the ones to be ignored removed.
 */
function getRelevantInputFields(inputFields) {
    const inputTypesToIgnore = { checkbox: 'checkbox', radio: 'radio', button: 'button', hidden: 'hidden' };
    return [...inputFields].filter(input => input.type !== inputTypesToIgnore[input.type]);
}

/**
 * @function handleInputFocusInOut
 * @private
 * @description Adds or removes the .active-label class from a label element if it exists.
 * @param {event} event - The focusin or focusout event.
 * @returns {void} Early exit if the label element is not found.
 */
function handleInputFocusInOut(event) {
    const { target, type } = event;
    let classListMethod = 'add';

    if (type === 'focusout' && target.value === '') classListMethod = 'remove'; // If the event type is focusout and there's no value remove the class
    target.classList[classListMethod]('active-label');
}

/**
 * @function initialiseInputLabelAnimation
 * @public
 * @description Handles the animation of a label when an input is focussed in or out.
 * @returns {void} Exits the function if there are no formElements found.
 */
function initialiseInputLabelAnimation() {
    const { forms } = selectors;
    const formElementsArray = [...document.querySelectorAll(forms)];
    if (!formElementsArray.length) return;

    let relevantInputFields = formElementsArray.map(form => getRelevantInputFields(form.querySelectorAll('input')));

    // Sometimes, if there are multiple forms on the page, relevantInputFields can become an array of arrays, so we need to flatten it
    relevantInputFields = [].concat(...relevantInputFields);
    relevantInputFields.forEach(inputField => {
        inputField.addEventListener('focusin', handleInputFocusInOut);
        inputField.addEventListener('focusout', handleInputFocusInOut);
        if (!inputField.value) return;

        if (inputField) inputField.classList.add('active-label'); // Add class if there's a value on pade load
    });
}

module.exports = {
    init: initialiseInputLabelAnimation
};
