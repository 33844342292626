'use strict';

// shared variables are defined in SCSS
const sharedVariables = require('../scss/_exports.scss');

/**
 * Events for use with pubsub
 * This way events that are used in the application can be tracked
 * When adding a new subscription, make sure to add them here.
 */
const pubSubEvents = {
    hideNotification: 'notification.hide',
    mailChimpSuccess: 'mailchimp.success',
    showNotification: 'notification.show',
    wishlist: {
        clickAddToWishlist: 'wishlist.clickAddToWishlist',
        addToWishlist: 'wishlist.addToWishlist',
        displayMessage: 'wishlist.displayMessage',
        updateAmount: 'wishlist.updateAmount'
    }
};

const events = {
    ajaxSuccess: 'ajaxSuccess',
    beforeAddToCart: 'product:beforeAddToCart',
    blur: 'blur',
    bonusProductSelect: 'bonusproduct:updateSelectButton',
    bsCollapseHidden: 'hidden.bs.collapse',
    bsCollapseHide: 'hise.bs.collapse',
    bsCollapseShow: 'show.bs.collapse',
    bsCollapseShown: 'shown.bs.collapse',
    bsModalHidden: 'hidden.bs.modal',
    bsModalHide: 'hide.bs.modal',
    bsModalShow: 'show.bs.modal',
    bsModalShown: 'shown.bs.modal',
    cart: 'cart:chequeAdded',
    change: 'change',
    click: 'click',
    focusin: 'focusin',
    focusout: 'focusout',
    hide: 'hide',
    idle: 'idle',
    keydown: 'keydown',
    keypress: 'keypress',
    keyup: 'keyup',
    load: 'load',
    loginModalShown: 'loginModal:shown',
    minicartTriggerPopover: 'minicart:triggerPopover',
    modalPanes: {
        next: 'modal-pane:next',
        prev: 'modal-pane:prev'
    },
    mouseenter: 'mouseenter',
    mouseleave: 'mouseleave',
    productAfterAddToCart: 'product:afterAddToCart',
    productAfterAttrValuesUpdate: 'product:updateAttrValues',
    productAfterAttributeSelect: 'product:afterAttributeSelect',
    productAfterRemoveFromCart: 'product:afterRemoveFromCart',
    productAvailabilityUpdated: 'product:updateAvailability',
    productBeforeAttributeSelect: 'product:beforeAttributeSelect',
    productChangeQuantity: 'product:changeQuantity',
    productStatusUpdate: 'product:statusUpdate',
    productTileClick: 'productTileClick',
    productPromotionClick: 'productPromotionClick',
    productUpdateAddToCart: 'product:updateAddToCart',
    checkoutOption: 'checkoutOption',
    promotionClick: 'promotionClick',
    resize: 'resize',
    scroll: 'scroll',
    searchFilter: 'search:filter',
    searchSort: 'search:sort',
    setShippingMethodSelection: 'setShippingMethodSelection',
    show: 'show',
    showQuickview: 'quickview:show',
    slick: {
        unslick: 'unslick'
    },
    start: 'start',
    submit: 'submit',
    tilesLoaded: 'tilesloaded',
    toggleMobileMenu: 'menu:toggleMobileMenu',
    toggleModal: 'toggleModal',
    touchend: 'touchend',
    touchstart: 'touchstart',
    transitionEnd: 'transitionend',
    update: 'update'
};

const selectors = {
    account: {
        notAuthenticated: '.js-not-authenticated'
    },
    backdrop: '.modal-backdrop',
    body: 'body',
    column: '[class*="col-"]',
    footer: 'footer',
    form: {
        checkbox: 'checkbox',
        form: 'form',
        input: 'input',
        select: 'select',
        selectedOption: 'option:selected',
        selectedRadio: 'input:checked',
        textarea: 'textarea',
        disabledField: {
            enabled: 'input.enabled',
            disabled: 'input.disabled'
        }
    },
    header: 'header',
    html: 'html',
    htmlBody: 'html, body',
    img: 'img',
    input: 'input',
    listItem: 'li',
    modal: '.modal',
    notTouch: 'html:not(.touch)',
    modalPanes: {
        activePane: '.modal-pane--active',
        container: '.js-modal-pane-container',
        pane: '.js-modal-pane'
    },
    page: '.js-page',
    product: {
        addToCart: '.js-add-to-cart',
        addToWishlist: '.js-add-to-wishlist',
        attributes: {
            container: '.product-attributes',
            item: '.product-attributes__item',
            message: '.product-attribute__message',
            selected: '.product-attributes__item.selected'
        },
        availabilityMsg: '.js-availability-msg',
        jsProductContainer: '.js-product',
        detailPage: '.product-detail-page',
        productContainer: '.product-detail',
        productID: '.product-id',
        productOption: '.product-option',
        productOptionSelected: '.options-select option:selected'
    },
    content: {
        tile: '[data-js=content-tile]'
    },
    promotion: {
        tile: '[data-js-content=promotion-tile]'
    },
    script: 'script',
    slick: {
        initialized: '.slick-initialized',
        all: '[data-slick-regular]',
        slider: '.slick-slider',
        track: '.slick-track',
        nextButton: '.slick-next',
        slide: '.slick-slide',
        currentSlide: '.slick-current',
        notInitialized: ':not(.slick-initialized)'
    },
    unOrderedListItem: 'ul',
    row: '.row',
    contentSearch: '.content-search'
};

const classes = {
    active: 'active',
    childExpanded: 'child-expanded',
    collapsed: 'collapsed',
    disabled: 'disabled',
    dBlock: 'd-block',
    dInlineBlock: 'd-inline-block',
    dNone: 'd-none',
    expanded: 'expanded',
    hide: 'hide',
    hover: 'hover',
    invalid: 'is-invalid',
    modalOpen: 'modal-open',
    product: {
        attributes: {
            errorState: 'product-attributes--error'
        },
        isInWishlist: 'is-in-wishlist'
    },
    modalPanes: {
        activePane: 'modal-pane--active'
    },
    scrollLock: 'scroll-lock',
    selectable: 'selectable',
    selected: 'selected',
    show: 'show',
    slick: {
        initialized: 'slick-initialized',
        currentSlide: 'slick-current',
        disabled: 'slick-disabled'
    },
    unselectable: 'unselectable'
};

const attributes = {
    action: 'action',
    actionUrl: 'action-url',
    addressId: 'address-id',
    alt: 'alt',
    ariaExpanded: 'aria-expanded',
    ariaPopUp: 'aria-haspopup',
    attrValue: 'data-attr-value',
    attribute: 'data-attr',
    attributeValue: 'data-attr-value',
    available: 'available',
    checked: 'checked',
    class: 'class',
    color: 'color',
    dataToggle: 'data-toggle',
    dataUrl: 'data-url',
    storeID: 'storeid',
    disabled: 'disabled',
    href: 'href',
    id: 'id',
    name: 'name',
    outerHTML: 'outerHTML',
    pattern: 'pattern',
    pid: 'pid',
    product: {
        isInWishlist: 'data-isinwishlist',
        optionID: 'data-option-id',
        productID: 'data-pid',
        productVariationID: 'data-pvid',
        valueID: 'data-value-id'
    },
    quantity: 'data-qty',
    readyToOrder: 'ready-to-order',
    selected: 'selected',
    slick: 'slick',
    slickConfig: 'slick-config',
    src: 'src',
    srcset: 'srcset',
    style: 'style',
    target: 'target',
    dataTarget: 'data-target',
    textSelect: 'text-select',
    textSelected: 'text-selected',
    title: 'title',
    translationResource: 'resource',
    uuid: 'uuid',
    url: 'url',
    urlAdd: 'data-href-add',
    urlRemove: 'data-href-remove',
    value: 'value'
};

const pipelines = {
    cartShow: 'Cart-Show',
    checkoutStep: 'Checkout-Step'
};

const states = {
    isAnimating: 'animating'
};

const buffer = {
    cssTransitionTime: 50
};

const times = {
    scrollToAnimation: 500,
    cssTransitionTime: 300 + buffer.cssTransitionTime,
    heartTransitionTime: 700 + buffer.cssTransitionTime
};

const breakpoints = {
    xs: parseFloat(sharedVariables.breakpoint_xs),
    sm: parseFloat(sharedVariables.breakpoint_sm),
    md: parseFloat(sharedVariables.breakpoint_md),
    lg: parseFloat(sharedVariables.breakpoint_lg),
    xl: parseFloat(sharedVariables.breakpoint_xl)
};

const cookies = {};
const queryParams = {};

module.exports = {
    pubSubEvents: pubSubEvents,
    events: events,
    selectors: selectors,
    classes: classes,
    attributes: attributes,
    pipelines: pipelines,
    states: states,
    times: times,
    breakpoints: breakpoints,
    cookies: cookies,
    queryParams: queryParams
};
