'use strict';

const CONST = require('plugin_frontend_core/constants');

const selectors = {
    product: {
        quickviewSlide: '.js-product-quickview-slide',
        quickviewSlideLink: '.js-product-quickview-slide a',
        attributes: {
            container: '.product-attributes',
            item: '.product-attributes__item',
            selected: '.product-attributes__item.selected'
        }
    }
};

const classes = {
    product: {
        attributes: {
            errorState: 'product-attributes--error'
        }
    }
};

module.exports = {
    addToWishlist: function () {
        $(document).on(CONST.events.click, CONST.selectors.product.addToWishlist, function clickAddToWishlist(e) {
            e.preventDefault();

            const $self = $(this);
            const isPDP = $self.closest(CONST.selectors.product.detailPage).length > 0;
            if (isPDP) {
                $(document).off(CONST.events.click, CONST.selectors.product.addToWishlist, clickAddToWishlist);
                return;
            }
            const $productContainer = $self.closest(CONST.selectors.product.jsProductContainer);
            let isReady = true;

            // Loop over all attributes
            const $productAttributes = $productContainer.find(CONST.selectors.product.attributes.container);
            $productAttributes.each(function () {
                const $productAttribute = $(this);
                const hasAttributeItems = $productAttribute.find(CONST.selectors.product.attributes.item).length > 0; // does the attribute have any items/variations?
                const noSelectedAttribute = $productAttribute.find(CONST.selectors.product.attributes.selected).length === 0; // does the attribute not have a selected item/variation?

                if (hasAttributeItems && noSelectedAttribute) {
                    $productAttribute.addClass(CONST.classes.product.attributes.errorState);
                    isReady = false;
                    e.stopImmediatePropagation();
                }
            });

            if (!isReady) {
                return false;
            }

            const pid = $productContainer.attr(CONST.attributes.product.productID);
            const urlAdd = $self.attr(CONST.attributes.urlAdd);
            const urlRemove = $self.attr(CONST.attributes.urlRemove);

            const addToWishlistData = {
                origin: 'tile.js',
                pid: pid,
                trigger: $self,
                urlAdd: urlAdd,
                urlRemove: urlRemove
            };

            window.pubSub.publish(CONST.pubSubEvents.wishlist.clickAddToWishlist, addToWishlistData);
        });
    },
    variationChange: function () {
        $(document).on(CONST.events.click, selectors.product.quickviewSlideLink, function tileVariationChange(e) {
            e.preventDefault();

            const $link = $(this);
            const $currSlide = $link.closest(CONST.selectors.slick.slide);
            const $productContainer = $link.closest(CONST.selectors.product.jsProductContainer);
            const $productAttributes = $productContainer.find(selectors.product.attributes.container);
            const pid = $link.attr(CONST.attributes.product.productID);
            const $wishlistTrigger = $productContainer.find(CONST.selectors.product.addToWishlist);

            // Update some attributes
            $productContainer.attr(CONST.attributes.product.productID, pid);
            $productContainer.find(CONST.selectors.product.productContainer).attr(CONST.attributes.product.productID, pid);
            $productContainer.find(CONST.selectors.product.addToCart).attr(CONST.attributes.product.productID, pid);

            // Remove the error class
            $productAttributes.removeClass(classes.product.attributes.errorState);

            // Add an active/selected class to the current slide and remove it on the others
            $currSlide
                .addClass(CONST.classes.slick.currentSlide)
                .addClass(CONST.classes.selected)

                .siblings(CONST.selectors.slick.slide)
                .removeClass(CONST.classes.slick.currentSlide)
                .removeClass(CONST.classes.selected);

            // Update the 'is in wishlist' attribute for product attributes such as size
            if ($link.attr(CONST.attributes.product.isInWishlist) === 'true') {
                $wishlistTrigger.addClass(CONST.classes.product.isInWishlist);
            } else {
                $wishlistTrigger.removeClass(CONST.classes.product.isInWishlist);
            }
        });
    }
};
