window.pubSub = require('plugin_frontend_components/components/pubsub');

const modal = require('plugin_frontend_components/components/modal');
const sliders = require('plugin_frontend_components/components/sliders');
const bannerCarousel = require('plugin_frontend_components/components/banner-carousel');
const productCarousel = require('plugin_frontend_components/product/product-carousel');
const countdownTimer = require('plugin_frontend_components/components/countdownTimer');
const instagramFeed = require('plugin_frontend_components/components/instagramFeed');

window.onjQueryLoad(function () {
    require('plugin_frontend_components/components/notifications');
    require('slick-carousel'); // Load slick-carousel in global as we can expect this to be on multiple pages

    modal.init();
    sliders.init();

    // This is for the countdown timer for the page designer content tile component
    countdownTimer.init();

    // Instagram feed for inspiration page
    instagramFeed.init();

    // The carousels are loaded in global.js right now because we currently don't have a work around for the problem where
    // Component/page files are loaded before global.js (containing the slick code)
    bannerCarousel.init();
    productCarousel.init();

    // Trigger Bootstrap tooltip
    $('[data-toggle="tooltip"]').tooltip();

}, true);
