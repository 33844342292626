/**
 * pubsub.js
 *
 * A tiny, optimized, tested, standalone and robust
 * pubsub implementation supporting different javascript environments
 *
 * @author Federico "Lox" Lucignano <http://plus.ly/federico.lox>
 *
 * @see https://github.com/federico-lox/pubsub.js
 */

/**
 * global define, module
 */
var pubSub = (function (context) {
    'use strict';
    // the channel subscription hash
    var channels = {};
    // help minification
    var funcType = Function;

    /**
     * @public
     *
     * Publish some data on a channel
     *
     * @param {string} channel The channel to publish on
     * @param {*} argument The data to publish, the function supports
     * as many data parameters as needed
     *
     * @example Publish stuff on '/some/channel'.
     * Anything subscribed will be called with a function
     * signature like: function(a,b,c){ ... }
     *
     * PubSub.publish(
     *		"/some/channel", "a", "b",
     *		{total: 10, min: 1, max: 3}
     * );
     */
    function publish() {
        // help minification
        var args = arguments,
            // args[0] is the channel
            subs = channels[args[0]],
            len,
            params,
            x;

        if (subs) {
            len = subs.length;
            params = (args.length > 1) ?
                Array.prototype.splice.call(args, 1) : [];

            // run the callbacks asynchronously,
            // do not block the main execution process
            setTimeout(
                function () {
                    // executes callbacks in the order
                    // in which they were registered
                    for (x = 0; x < len; x += 1) {
                        subs[x].apply(context, params);
                    }

                    // clear references to allow garbage collection
                    subs = null;
                    params = null;
                },
                0
            );
        }
    }

    /**
     * @public
     *
     * Register a callback on a channel
     *
     * @param {string} channel The channel to subscribe to
     * @param {function} callback The event handler, any time something is
     * published on a subscribed channel, the callback will be called
     * with the published array as ordered arguments
     *
     * @returns {Object} Array A handle which can be used to unsubscribe this
     * particular subscription
     *
     * @example PubSub.subscribe(
     *		"/some/channel",
     *		function(a, b, c){ ... }
     *	);
     */
    function subscribe(channel, callback) {
        if (typeof channel !== 'string') {
            throw new Error('invalid or missing channel');
        }

        if (!(callback instanceof funcType)) {
            throw new Error('invalid or missing callback');
        }

        if (!channels[channel]) {
            channels[channel] = [];
        }

        channels[channel].push(callback);

        return {
            channel: channel,
            callback: callback
        };
    }

    /**
     * @public
     *
     * Disconnect a subscribed function f.
     *
     * @param {*} handle The return value from a subscribe call or the
     * name of a channel as a String
     * @param {function} [callback] The event handler originaally
     * registered, not needed if handle contains the return value
     * of subscribe
     *
     * @example
     * var handle = PubSub.subscribe("/some/channel", function(){});
     * PubSub.unsubscribe(handle);
     *
     * or
     *
     * PubSub.unsubscribe("/some/channel", callback);
     */
    function unsubscribe(handle, callback) {
        if (handle.channel && handle.callback) {
          /* eslint-disable */
          // disabled eslint to keep consistent to lib from github
          callback = handle.callback;
          handle = handle.channel;
          /* eslint-enable */
        }

        if (typeof handle !== 'string') {
            throw new Error('invalid or missing channel');
        }

        if (!(callback instanceof funcType)) {
            throw new Error('invalid or missing callback');
        }

        var subs = channels[handle],
            x,
            y = (subs instanceof Array) ? subs.length : 0;

        for (x = 0; x < y; x += 1) {
            if (subs[x] === callback) {
                subs.splice(x, 1);
                break;
            }
        }
    }

    return {
        publish: publish,
        subscribe: subscribe,
        unsubscribe: unsubscribe
    };
}(this));

module.exports = pubSub;
