$(document).ready(function () {
    $('.js-quickrefinement-pd').each((index, element) => {
        const $element = $(element);
        const config = {
            infinite: false,
            slidesToShow: 6,
            swipeToSlide: true,
            mobileFirst: true,
            variableWidth: true,
            responsive: []
        };
        const slick = $element.data('slick');
        if (!slick) {
            config.responsive.push({
                breakpoint: 768,
                settings: 'unslick'
            });
        }

        const initSize = $element.attr('data-init-size');
        if (initSize) {
            const slideIndex = $element.find('[data-size='+initSize+']').index();
            config.initialSlide = slideIndex;
        }

        $element.slick(config);
    });
});
