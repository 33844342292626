'use strict';

const CONST = require('../constants');

/**
 * @namespace cwUtil
 * - keep functions in order as exported
 * - group functions by type of adjustments, e.g. device and screen size detection, ajax, typechecks.
 * - think similar setup as lodash.
 * - keep functions and exports in alphabetical order.
 */

/* eslint-disable no-use-before-define */
module.exports = {
    appendParamToURL,
    debounce,
    doubleTapElement,
    exists,
    getGeoLocation,
    getWindowHeight,
    isKeyInObject,
    isLargeScreen,
    isMediumScreen,
    isSmallScreen,
    isTouch,
    loadJsonP,
    randomString,
    rerenderElement,
    scrollToElement,
    updateComponentConfig,
    insertParam,
    removeURLParameter,
    updateQueryParams,
    isMediaBreakpoint,
    replaceSpaceWithPeriod,
    slickPreventBeyondEdge
};
/* eslint-enable */

/**
 * Appends paramaters to a new url
 * @param {*} oldUrl string
 * @param {*} name value
 * @param {*} value value
 * @returns {string} new url
 */
function appendParamToURL(oldUrl, name, value) {
    var queryStart = '?';
    if (oldUrl.indexOf(queryStart) !== -1 && oldUrl.indexOf(queryStart) !== oldUrl.length - 1) {
        queryStart = '&';
    }

    if (queryStart === '?' && oldUrl.indexOf(queryStart) !== -1) {
        queryStart = '';
    }

    return oldUrl + queryStart + name + '=' + encodeURIComponent(value);
}

/**
 * @LROS TODO: refactor during architecture sprint - https://clockworkjira.atlassian.net/browse/CWO-244
 * Debounce util
 * @memberof cwUtil
 * @param {Function} func callback to debounce
 * @param {*} wait in ms for settimeout
 * @param {boolean} immediate ovverride to call callback immediately
 * @returns {function} context to call callback in
 */
function debounce(func, wait, immediate) {
    var timeout;

    return function () {
        var context = this,
            args = arguments,
            callNow = immediate && !timeout;

        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

/**
 * @LROS TODO: refactor during architecture sprint - https://clockworkjira.atlassian.net/browse/CWO-244
 * Checks if device screen width is larger than md screen
 * @memberof cwUtil
 * @param {HtmlElement} element to check tap event against
 * @returns {boolean} yes if less than md constant
 */
function doubleTapElement(element) {
    if (window.lastTap === element) {
        return true;
    }

    window.lastTap = element;
    return false;
}

/**
* getGeoLocation - Gets the current GPS location of the user
@param {function} callback - The function to call after getGeoLocation is done
@returns {function} callback - The callback function in which the location is passed
*/
function getGeoLocation(callback) {
    navigator.geolocation.getCurrentPosition(function geoLocationCallback(geoLocation) {
        return callback(geoLocation);
    });
}

/**
 * @public exists
 * @param {*} input - The input to verify its existance
 * @returns {boolean} - Boolean value stating the existance of the input
 * @description
 * Verifies existance of a certain element by checking for type, null and length
 * Usage: use with either querySelector() or querySelectorAll()
 * querySelector will not have a 'length', e.g. this would return true if other conditions are true
 * querySelectorAll will have a 'length', e.g. this would return true if all conditions are true
 */
function exists(input) {
    return typeof input !== 'undefined' && input !== null && ('length' in input ? input.length > 0 : true);
}

/**
 * @LROS TODO: refactor during architecture sprint - https://clockworkjira.atlassian.net/browse/CWO-244
 * @returns {number} browser window height
 */
function getWindowHeight() {
    return window.outerHeight
        || window.innerHeight
        || document.documentElement.clientHeight;
}

/**
* isKeyInObject
*/
const isKeyInObject = (obj, key) => Object.prototype.hasOwnProperty.call(obj, key);

/**
 * @deprecated in favor of using isMediaBreakpoint
 * @memberof cwUtil
 * @improvement: share screen size context with scss
 * @returns {boolean} yes if less than md constant
 */
function isLargeScreen() {
    return $(window).outerWidth() >= 992;
}
/**
 * @memberof cwUtil
 * @param {string} breakpointKey from constants that should match css breakpoint
 * @param {boolean} [isUp] by default is true since mobile first, reverses condition
 * @return {boolean} whether window is larger than specified breakpoint
 */
function isMediaBreakpoint(breakpointKey, isUp = true) {
    if (!isKeyInObject(CONST.breakpoints, breakpointKey)) {
        throw new Error(`cwUtil.isMediaBreakpoint: ${breakpointKey} -- does not exist in constants breakpoints object`);
    }

    const outerWidth = $(window).outerWidth();
    const breakpointValue = CONST.breakpoints[breakpointKey];
    return isUp ? (outerWidth > breakpointValue) : (outerWidth < (breakpointValue - 1));
}

/**
 * @deprecated in favor of using isMediaBreakpoint
 * @memberof cwUtil
 * @improvement: share screen size context with scss
 * @returns {boolean} yes if less than md constant
 */
function isMediumScreen() {
    return $(window).outerWidth() < 992;
}

/**
 * Checks if device is small screen
 * @deprecated in favor of using isMediaBreakpoint
 * @memberof cwUtil
 * @improvement: share screen size context with scss
 * @returns {boolean} yes if less than sm constant
 */
function isSmallScreen() {
    return $(window).outerWidth() < 520;
}

/**
 * Checks if browser context is a touch device
 * @memberof cwUtil
 * @returns {boolean} yes if is touch device
 */
function isTouch() {
    return (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0));
}

/**
 * @typedef {Object} loadJsonPConfig
 * @param {string} url to execute jsonp call against
 * @param {string} callBackFormat
 * @param {function} onError callback to handle error events
 * @param {function} onSuccess callback to handle success events
 */

/**
 * Util for executing a jsonp call, for use with apis that have CORS issues or don't have ajax available
 * @memberof cwUtil
 * @param {loadJsonPConfig} config for jsonp execution
 * @returns {void}
 */
function loadJsonP(config) {
    // Create script with url and callback (if specified)
    var ref = window.document.getElementsByTagName('script')[0];
    var script = window.document.createElement('script');
    var callbackScriptName = 'jsonp_' + randomString(10);

    window[callbackScriptName] = config.onSuccess;
    script.src = config.url + config.callBackFormat + callbackScriptName;

    // Insert script tag into the DOM (append to <head>)
    ref.parentNode.insertBefore(script, ref);

    // After the script is loaded (and executed), remove it
    script.onload = function () {
        this.remove();
    };

    if (config && config.onError) {
        script.onerror = config.onError;
    }
}

/**
 * Generate a random string for jsonP callback function assignment
 * Helper for loadJsonP function
 * reference: https://github.com/larryosborn/JSONP/blob/master/src/jsonp.coffee
 * @private
 * @param {number} length of string to generate
 * @returns {string} random string for jsonp_callback
 */
function randomString(length) {
    var len = length || 10;
    var str = '';

    while (str.length < len) {
        str += Math.random().toString(36).slice(2, 3);
    }

    return str;
}

/**
* util to remove query params form a url
* @param {string} url to remove param from
* @param {string} parameter to remove
* @returns {string} new url
*/
function removeURLParameter(url, parameter) {
    // prefer to use l.search if you have a location/link object
    var urlparts = url && url.split('?');
    var newUrl = url;
    if (urlparts && urlparts.length >= 2) {
        var prefix = encodeURIComponent(parameter) + '=';
        var pars = urlparts[1].split(/[&;]/g);

        // reverse iteration as may be destructive

        var lenPars = pars.length;
        for (var i = lenPars; i-- > 0;) {
            // idiom for string.startsWith
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        newUrl = urlparts[0] + (lenPars > 0 ? '?' + pars.join('&') : '');
        return newUrl;
    }

    return newUrl;
}

/**
* replaceSpaceWithPeriod
*/
const replaceSpaceWithPeriod = (string) => string.replace(/ /g, '.');

/**
 * Util to rerender an element applying the same styles it had
 * @memberof cwUtil
 * @param {HTMLElement} element to rerender
 * @returns {void}
 */
function rerenderElement(element) {
    var cssProperty = 'transform';
    var $element = $(element);
    var orgStyling = $element.eq(0).css(cssProperty);

    $(element).eq(0).css(cssProperty, 'rotateZ(0deg)');

    setTimeout(function () {
        $(element).eq(0).css(cssProperty, orgStyling);
    }, 10);
}

/**
 * @public scrollToElement
 * @param {HTMLElement} element - the element to scroll to
 * @param {Object} [config] - A configiguration Object
 * @param {number} [config.speed=300] - The scroll speed
 * @param {number} [config.offset=0] - an offset where to scroll to
 */
function scrollToElement(element, config) {
    if (!element) {
        return;
    }
    var conf = config || {};
    var rect = element.getBoundingClientRect();
    var win = element.ownerDocument.defaultView;
    var top = rect.top + win.pageYOffset;

    var duration = conf.speed || 300;

    if (conf.offset) top -= conf.offset;

    $('html, body').animate({
        scrollTop: top
    }, duration);
}

/**
 * Retrieves data-component-config attribute from a node if it exists and updates the module configuration with it
 * @memberof cwUtil
 * @param {HtmlElement} element to retrieve configuration from
 * @param {Object} config to mutate and update
 * @returns {Object} updated config if applicable, default if not
 */
function updateComponentConfig(element, config) {
    var configAttribute = 'data-component-settings';
    var rawElementConfig = element.getAttribute(configAttribute);
    var elementConfig;

    if (!rawElementConfig) {
        return config;
    }

    try {
        elementConfig = JSON.parse(rawElementConfig);
    } catch (e) {
        return config;
    }

    return $.extend(true, config, elementConfig);
}
/**
 * Removes query params from url and passes new ones
 * @param {string} url value
 * @param {string} key value
 * @param {*} value value
 * @returns {string} new url
 */
function updateQueryParams(url, key, value) {
    var removedCurrentParamUrl = removeURLParameter(url, key);
    var newurl = appendParamToURL(removedCurrentParamUrl, key, value);

    return newurl;
}

/**
 * Push state supports ie10+ https://caniuse.com/#search=pushstate
 * will use graceful degradation here
 * @param {string} key to add as a param
 * @param {*} value to set the pareter to
 * @returns {void}
 */
function insertParam(key, value) {
    if (history.pushState) {
        var currentUrl = window.location.href;
        // remove any param for the same key
        var newurl = updateQueryParams(currentUrl, key, value);
        window.history.pushState({ path: newurl }, '', newurl);
    }
}

/**
* slickPreventBeyondEdge
* This custom function will make sure that, if 'variableWidth' is true, it's not possible to
* continue going right even when the last slide is already fully in view.
* @param {JQuery|HTMLElement} $carousel - The slick carousel
*/
function slickPreventBeyondEdge($carousel) {
    function preventBeyondEdge(slick) {
        var slickObj = slick || $carousel[0].slick;

        // Stop if 'variableWidth' is not set to true and use slick's default UI
        if (!slickObj.options || !slickObj.options.variableWidth) {
            return;
        }

        const $slickTrack = $carousel.find(CONST.selectors.slick.track);
        const $nextButton = $carousel.find(CONST.selectors.slick.nextButton);
        const $allSlides = $carousel.find(CONST.selectors.slick.slide);
        if (!$allSlides && $allSlides.length === 0) {
            return;
        }
        const $lastSlide = $allSlides.eq($allSlides.length - 1);

        // Wait for slick animation to be done before doing calculations
        const timeoutTime = slick && slickObj.options ? slickObj.options.speed : 0;

        setTimeout(() => {
            const trackPosition = $slickTrack.position().left;
            const lastSlideWidth = $lastSlide.width();
            const containerWidth = slickObj.listWidth;

            // $lastSlide.position().left returns a static position because it's relative to .slick-track.
            // This is why we add the position of .slick-track to determine the position of the last slide
            // relative to the slick-carousel.
            const lastSlideVisualPosition = $lastSlide.position().left + trackPosition;
            const lastSlideFullyInView = (lastSlideVisualPosition + lastSlideWidth) < containerWidth;

            if (lastSlideFullyInView) {
                $nextButton.attr(CONST.attributes.disabled, true).addClass(CONST.classes.slick.disabled);
            } else {
                $nextButton.attr(CONST.attributes.disabled, false).removeClass(CONST.classes.slick.disabled);
            }
        }, timeoutTime);
    }

    $carousel.on({
        beforeChange: (event, slick) => {
            preventBeyondEdge(slick);
        }
    });

    preventBeyondEdge(false);
}
