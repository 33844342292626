require('custom-event-polyfill');

const processInclude = require('app_storefront_base/util');
const maxjQueryLoadRetries = 5;
const jqueryLoadDelay = 500;
const jQueryLoadedEventType = 'jQueryLoaded';

const jQueryEvent = new CustomEvent(jQueryLoadedEventType, {
    bubbles: true,
    cancelable: false,
    currentTarget: document
});

/**
 * Use this when you want to load the callback only after jQuery is loaded.
 * @param {Function} callback - function to be called after jQuery is loaded
 * @param {boolean} waitForPageLoad - should you load right after jQuery loads, or also wait for the full document to load
 */
window.onjQueryLoad = function (callback, waitForPageLoad) {
    if (window.$ && window.jQuery) {
        if (waitForPageLoad) {
            $(document).ready(callback);
        } else {
            callback();
        }
    } else {
        document.addEventListener(jQueryLoadedEventType, function () {
            if (waitForPageLoad) {
                $(document).ready(callback);
            } else {
                callback();
            }
        });
    }
};

/**
 * Tries loading jQuery multiple times and fire an event after a successfull load
 * @param {number} currentRetry - current number of times it tried to load jQuery
 * @param {number} maxRetries - after which time script should avoid loading
 */
var loadJquery = function (currentRetry, maxRetries) {
    window.$ = window.jQuery = window.jQuery ? window.jQuery : require('jquery');

    if (window.$ && window.jQuery) {
        document.dispatchEvent(jQueryEvent);
        return;
    }
    if (currentRetry >= maxRetries) {
        return;
    }

    setTimeout(loadJquery(currentRetry + 1, maxRetries), jqueryLoadDelay);
};

loadJquery(0, maxjQueryLoadRetries);
require('lazysizes');

// Due to how SFRA is set up, we always need the cart.
// Most notably: If we don't include this here, switching variants on PDP will not work
// require('plugin_checkout/cart/cart')();

window.onjQueryLoad(function () {
    require('./app_storefront_base/main');
    require('plugin_frontend_core/global');
    require('plugin_navigation/global');

    // cartridgeModule files must be exported as module
    // e.g.: module.exports = {};
    processInclude(require('plugin_frontend_components/components/accordion'));
    processInclude(require('plugin_frontend_components/components/cookies'));
    processInclude(require('plugin_frontend_components/components/lazysizes'));
    processInclude(require('plugin_frontend_components/product/tile'));
    processInclude(require('plugin_wishlists/components/helper'));
    processInclude(require('module_pagedesigner_orange/components/sizeRefinement'));
    processInclude(require('plugin_frontend_components/components/input-label-animation'));
}, true);
