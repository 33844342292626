'use strict';

const CONST = require('plugin_frontend_core/constants');
const formValidation = require('../components/formValidation');

const selectors = {
    accountBtn: '.js-not-authenticated-user',
    loginOverlay: '.js-login-overlay',
    loginOverlayClose: '.js-overlay-close',
    loginPromoteModal: '.promoteLoginOverlay',
    loginSuccessModal: '.js-login-overlay__success',
    loginSuccessCloseBtn: '.loginSuccess-close-btn',
    mainModalBlock: '.js-login-overlay__main',
    redirectButton: '.js-redirect-button',
    overlayArrow: '.login-overlay__arrow',
    loginOverlayInner: '.login-overlay__inner'
};
var $loginOverlay = $(selectors.loginOverlay);
var $accountBtn = $(selectors.accountBtn);
var $loginOverlayClose = $(selectors.loginOverlayClose);
var $loginPromoteModal = $(selectors.loginPromoteModal);
var $loginPromoteModalCloseBtn = $loginPromoteModal.find($loginOverlayClose);
var $loginSuccessModal = $(selectors.loginSuccessModal);
var $loginSuccessCloseBtn = $(selectors.loginSuccessCloseBtn);
var $loginMailModalBlock = $(selectors.mainModalBlock);
var $redirectButton = $(selectors.redirectButton);
var $overlayArrow = $(selectors.overlayArrow);
var $loginOverlayInner = $(selectors.loginOverlayInner);

function showLoginOverlay() {
    $accountBtn.on(CONST.events.click, function (e) {
        e.preventDefault();
        $loginOverlay.css('display', 'block');
    });
}

function closeLoginOverlay() {
    $loginOverlay.css('display', 'none');
}

function closePromoteLoginPopup() {
    $loginPromoteModal.css('display', 'none');
}

function closeLoginOverlayMobile() {
    $loginOverlay.removeClass('show');
}

function closeLoginSuccessModal() {
    $loginSuccessModal.css('display', 'none');
}

// The viewport is less than 768 pixels wide
function loginOverlaySlider() {
    $accountBtn.on(CONST.events.click, function (e) {
        e.preventDefault();
        $loginOverlay.toggleClass('show');
    });
}

function submitLoginForm() {
    $('form.js-login-overlay-form').submit(function (e) {
        var form = $(this);
        e.preventDefault();
        var url = form.attr('action');
        form.spinner().start();
        form.trigger('login:submit', e);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: form.serialize(),
            success: function (data) {
                form.spinner().stop();
                if (!data.success) {
                    formValidation(form, data);
                    form.trigger('login:error', data);
                } else {
                    form.trigger('login:success', data);
                    $loginMailModalBlock.css('display', 'none');
                    $loginSuccessModal.css('display', 'block');
                    $loginOverlay.css('box-shadow', 'none');
                    $overlayArrow.addClass('show');
                    $loginOverlayInner.addClass('success');
                    const myTimeout = setTimeout(function () {
                        location.reload();
                    }, 2000);
                    $redirectButton.on('click', function () {
                        clearTimeout(myTimeout);
                    });
                }
            },
            error: function (data) {
                if (data.responseJSON.redirectUrl) {
                    window.location.href = data.responseJSON.redirectUrl;
                } else {
                    form.trigger('login:error', data);
                    form.spinner().stop();
                }
            }
        });
        return false;
    });
}

module.exports = function loginOverlay() {
    var mobileUP = window.matchMedia('(min-width: 768px)');
    var mobileBelow = window.matchMedia('(max-width: 767px)');

    // Login Overlay closing button event for Desktop
    if (mobileUP.matches) {
        showLoginOverlay();
        $loginOverlayClose.on('click', closeLoginOverlay);
    }
    // Login Overlay closing button event for Mobile
    if (mobileBelow.matches) {
        loginOverlaySlider();
        $loginOverlayClose.on('click', closeLoginOverlayMobile);
    }

    submitLoginForm();

    //  Promote Login Modal closing button event
    if ($loginPromoteModal.find($loginOverlayClose).length) {
        $loginPromoteModalCloseBtn.on('click', closePromoteLoginPopup);
    }
    $loginSuccessCloseBtn.on('click', closeLoginSuccessModal);
};
