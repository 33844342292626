const CONST = require('plugin_frontend_core/constants');

const selectors = {
    wrapper: '.js-notification',
    messageContainer: '.js-notification-message',
    closeTrigger: '.js-notification-close',
    siteHeader: '.site-header'
};

const classes = {
    success: 'is-success',
    error: 'is-error'
};

const settings = {
    animationDuration: 500,
    animationEasing: 'swing',
    hideDelay: 7000
};

const notifications = (function (win) {
    'use strict';
    let $notificationEl; 
    let $messageContainer;
    let $closeTrigger;
    let notificationIsOpen = false;

    /**
     * Clears notification of error or success type
     */
    function clearNotificationState() {
        $messageContainer.html('');
        $notificationEl.removeClass(classes.success);
        $notificationEl.removeClass(classes.error);
    }

    /**
     * setTopValue based on header and cookiebar height
     * @returns {string} height of site header
     */
    function getAnimationValue() {
        const notificationHeight = $notificationEl.outerHeight();
        const animation = {
            show: {
                bottom: 0,
                opacity: 1
            },
            hide: {
                bottom: -notificationHeight,
                opacity: 0
            }
        };

        return animation;
    }

    /**
     * Hide the notification
     * @param {function} callback to call on completion of animation
     */
    function hideNotification(callback) {
        const closeAnimation = getAnimationValue().hide;

        notificationIsOpen = false;
        $notificationEl.animate(
            closeAnimation,
            settings.animationDuration,
            settings.animationEasing,
            callback || function noop() {
                $notificationEl.hide();
            }
        );
    }

    var hideInterval;

    /**
     * @param {Object} config to display in notification
     */
    function showNotification(config) {
        let showAnimation;

        if (!config || !config.msg) {
            return;
        }

        clearInterval(hideInterval);

        if (notificationIsOpen) {
            hideNotification(function () {
                showNotification(config);
            });
            return;
        }

        clearNotificationState();
        showAnimation = getAnimationValue().show;
        hideInterval = setInterval(hideNotification, settings.hideDelay);

        $messageContainer.html(config.msg);
        $notificationEl.show().animate(showAnimation, settings.animationDuration);
        notificationIsOpen = true;

        if (!config.result) {
            return;
        }

        switch (config.result) {
            case 'error':
                $notificationEl.addClass(classes.error);
                break;
            default:
                $notificationEl.addClass(classes.success);
        }
    }

    /**
     * Initialize the module
     */
    function init() {
        $notificationEl = $(selectors.wrapper);

        if (!$notificationEl[0]) {
            throw new Error('No notification element present, add element or remove notifications.js from page');
        }

        $messageContainer = $($notificationEl.find(selectors.messageContainer));
        $closeTrigger = $($notificationEl.find(selectors.closeTrigger));

        $closeTrigger.on(CONST.events.click, hideNotification);
        win.pubSub.subscribe(CONST.pubSubEvents.showNotification, showNotification);
        win.pubSub.subscribe(CONST.pubSubEvents.hideNotification, hideNotification);
    }

    init();

    return {
        hide: hideNotification,
        show: showNotification
    };
}(window));

module.exports = notifications;
